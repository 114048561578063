<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Alert :message="requestError" type="alert-danger" v-if="requestError" />

    <div class="card" v-if="loading">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-center">
          <div class="spinner-border m-5" role="status">
            <span class="visually-hidden"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-if="!loading && !success">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-center my-5">
          <p>No Resource Found. Try Again</p>
        </div>
      </div>
    </div>

    <div class="row " v-if="!loading && success">
      <div class="col-md-12 mb-4">
        <h4 class="card-title my-3 float-right">
          <router-link
            :to="`/user/${$route.params.id}/enroll_courses`"
            class="btn btn-success btn-sm"
            >My Courses</router-link
          >
        </h4>
      </div>
    </div>
    <div
      class="container card content_margin_bottom pt-4"
      v-if="!loading && success"
    >
      <div class="table-wrapper">
        <table class="table table-borderless">
          <thead>
            <tr v-if="course.course.test_assesment">
              <td scope="col"><b>Course Test</b></td>
              <button
                class="btn btn-success btn-sm"
                :disabled="
                  course.test_attempts_left == 0 ||
                    course.test_status == 'passed'
                "
                @click="toTest(course.id, course.course.test_assesment.id)"
              >
                Take Test
              </button>
            </tr>
            <tr>
              <td scope="col"><b>Course Title</b></td>
              <td scope="col">{{ course.course.title }}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>Enrolled On</b></td>
              <td>{{ course.enrolled_on }}</td>
            </tr>
            <tr>
              <td><b>Course Status</b></td>
              <td>{{ course.status }}</td>
            </tr>
            <tr>
              <td><b>Test Status</b></td>
              <td>
                {{ course.test_status ? course.test_status : "Not Started" }}
              </td>
            </tr>
            <tr>
              <td><b>Test Attempts Left</b></td>
              <td>
                {{ course.test_attempts_left }}
              </td>
            </tr>
            <tr>
              <td><b>Test Attempts Taken</b></td>
              <td>
                {{ course.test_attempts_taken }}
              </td>
            </tr>
          </tbody>
        </table>
        <CourseContent :content="course.course.contents" />

        <div class="d-flex justify-content-end">
          <button
            :disabled="course.test_status != 'passed'"
            @click="toCert(course.e_cert, course.id)"
            class="btn btn-sm btn-success my-5 p-2 mr-3"
          >
            Download Certificate
          </button>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
/* eslint-disable no-unused-vars */
import toasterMixin from "@/mixins/toasterMixin.js";
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import store from "@/state/store";
import download from "downloadjs";
import CourseContent from "./CourseContent.vue";

export default {
  name: "User Course Details",
  components: {
    Layout,
    PageHeader,
    CourseContent,
  },
  page: {
    title: "Course Overview",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Course Overview",
      items: [
        {
          text: "User",
        },
        {
          text: "Course Overview",
          active: true,
        },
      ],
      course: {},
      userId: this.$route.params.id,
      requestError: null,
      courseId: this.$route.params.courseId,
      loading: false,
      success: false,
      showCert: false,
    };
  },
  methods: {
    toTest(testId, enrollmentId) {
      console.log(enrollmentId, testId);
      this.$router.push(
        `/user/${this.userId}/enroll_course/${this.courseId}/test/${enrollmentId}/${testId}`
      );
    },
    toCert(certs, enrollmentId) {
      let certId = certs.find((cert) => cert.enrollment === enrollmentId);

      store
        .dispatch("enroll/eCertDownload", certId.id)
        .then((response) => {
          const content = response.headers["content-type"];
          download(response.data, certId.cert, content);
        })
        .catch(() => {
          this.loading = false;
          this.success = false;
        });
    },

    fetchData() {
      this.loading = true;
      store
        .dispatch("enroll/myCourses", this.userId)
        .then((response) => {
          const courses = response.data.Enrollments;
          const course = courses.find(
            (course) => course.course_id == this.$route.params.courseId
          );
          console.log(this.$route.params.courseId);
          console.log(course);
          this.course = course;
          this.loading = false;
          this.success = true;
        })
        .catch(() => {
          this.requestError = "An error occured";
          this.loading = false;
          this.success = false;
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped>
b {
  font-weight: bolder;
}
.table-wrapper {
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.247);
}
</style>
