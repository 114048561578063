<template>
  <div class="accordion" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-1 variant="outline-info"
          >File Content(s)</b-button
        >
      </b-card-header>

      <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
        <div v-if="files && files.length">
          <b-card-body v-for="file in files" :key="file.id">
            <div class="border p-2 mb-2">
              <h5>{{ file.unit_name }}</h5>
              <b-card-text class="d-flex justify-content-around"
                >{{ file.file
                }}<i class="fas fa-file-image" style="font-size: 30px"></i
              ></b-card-text>
            </div>
          </b-card-body>
        </div>
        <div v-else>
          <b-card-text class="text-center py-3">No File Content(s)</b-card-text>
        </div>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="outline-info"
          >Video Content(s)</b-button
        >
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
        <div v-if="videos && videos.length">
          <b-card-body v-for="video in videos" :key="video.id">
            <div class="border p-2 mb-2">
              <b-card-text> {{ video.unit_name }} </b-card-text>
              <b-card-text
                ><a :href="video.url" class="d-flex justify-content-around"
                  ><p>{{ video.url }}</p>
                  <i class="fab fa-youtube" style="font-size: 30px"></i></a
              ></b-card-text>
            </div>
          </b-card-body>
        </div>
        <div v-else>
          <b-card-text class="text-center py-3"
            >No Video Content(s)</b-card-text
          >
        </div>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-3 variant="outline-info"
          >Article Content(s)</b-button
        >
      </b-card-header>
      <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
        <div v-if="articles && articles.length">
          <b-card-body v-for="article in articles" :key="article.id">
            <div class="border p-2 mb-2">
              <b-card-text> {{ article.unit_name }} </b-card-text>
              <b-card-text
                ><a
                  :href="article.url"
                  target="_blank"
                  class="d-flex justify-content-around"
                  ><p>{{ article.url }}></p>
                  <i class="fas fa-globe-europe" style="font-size: 30px"></i></a
              ></b-card-text>
            </div>
          </b-card-body>
        </div>
        <div v-else>
          <b-card-text class="text-center py-3"
            >No Article Content(s)</b-card-text
          >
        </div>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
export default {
  props: ["content"],
  data() {
    return {
      videos: [],
      files: [],
      articles: [],
    };
  },
  methods: {
    filterContents() {
      this.content.forEach((content) => {
        let model = content.content_type.model;
        if (model == "video") {
          this.videos.push(content.content);
        } else if (model == "article") {
          this.articles.push(content.content);
        } else {
          this.files.push(content.content);
        }
      });
    },
  },
  created() {
    this.filterContents();
    console.log(this.files);
  },
};
</script>
